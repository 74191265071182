// import { render } from "@testing-library/react";
import React from "react";

class JoinPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Join Page</h1>
      </div>
    );
  }
}

export default JoinPage;
